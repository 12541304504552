import {InlineViewStrategy, noView} from 'aurelia-framework';
import * as _ from 'lodash';

@noView
export class Custom
{
    column;
    item;

    activate(model) {
        if (!model) return;

        this.column = model.column;
        this.item = model.item;

        this.options = this.column.options;
        this.value = this.getValue();
    }

    getValue()
    {
        if (_.has(this.item, this.column.property)) {
            return _.get(this.item, this.column.property);
        }

        return '';
    }

    getViewStrategy() {
        return new InlineViewStrategy('<template>' + this.column.template + '</template>');
    }
}

