import getPropertyByPath from "../../utilities/get-property-by-path"

export class AbstractCell {
    _defaultOptions = {}

    bind() {
        this.value = this.getValue();
        // noinspection JSUnresolvedVariable
        this.options = {...this._defaultOptions, ...this.column?.options}
        this.valueChanged()
    }

    valueChanged() {
    }

    getValue() {
        // noinspection JSUnresolvedVariable
        return getPropertyByPath(this.item, this.column.property)
    }
}
